<template>
  <div class="info-container flex">
    <div class="left">
      <div class="swiper carInfoSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in list" :key="index">
            <img :src="item.appearanceUrl" />
          </div>
        </div>
      </div>
      <!--   :class="activeIndex === 0 ? 'disabled' : 'hover'"   -->
      <div class="left-icon flex col-center row-center hover" @click="changeSwiper('pre')">
        <div class="iconfont icon-zuojiantou"></div>
      </div>
      <!--   :class="activeIndex === list.length - 1 ? 'disabled' : 'hover'"   -->
      <div class="right-icon flex col-center row-center hover" @click="changeSwiper('next')">
        <div class="iconfont icon-youjiantou"></div>
      </div>
      <div class="custom-pagination flex row-center">
        <div
          class="circle"
          v-for="(item, index) in list"
          :key="index"
          :class="index === activeIndex ? 'active' : ''"
        ></div>
      </div>
    </div>
    <div class="right" :key="activeIndex" v-if="list.length > 0" @mouseover="over" @mouseout="out">
      <div class="name text-ellipsis-2">{{ list[activeIndex].name }}</div>
      <img :src="list[activeIndex].interiorUrl" />
      <div class="desc-box">
        <div class="desc">{{ $t('carIntroduction.maximumPassengers') }}</div>
        <div class="info-box flex">
          <div class="iconfont icon-chengke"></div>
          <div class="info">
            {{ $t('carIntroduction.maximumPassengersLabel') }}
            {{
              $i18n.locale === 'en'
                ? list[activeIndex].peopleNumber === 1
                  ? list[activeIndex].peopleNumber +
                    ' ' +
                    $t('carIntroduction.maximumPassengersUnit')
                  : list[activeIndex].peopleNumber + ' people'
                : list[activeIndex].peopleNumber + ' ' + $t('carIntroduction.maximumPassengersUnit')
            }}
          </div>
        </div>
      </div>
      <div class="desc-box">
        <div class="desc">{{ $t('carIntroduction.highestPlacement') }}</div>
        <div class="info-box flex">
          <div class="iconfont icon-hangli"></div>
          <div class="info">
            {{ $t('carIntroduction.highestPlacementLabel') }}
            {{
              $i18n.locale === 'en'
                ? list[activeIndex].luggageNumber === 1
                  ? list[activeIndex].luggageNumber +
                    ' ' +
                    $t('carIntroduction.highestPlacementUnit')
                  : list[activeIndex].luggageNumber +
                    ' ' +
                    $t('carIntroduction.highestPlacementUnit') +
                    's'
                : list[activeIndex].luggageNumber + ' ' + $t('carIntroduction.highestPlacementUnit')
            }}
          </div>
        </div>
      </div>
      <div
        class="desc-box"
        v-if="list[activeIndex].price !== null && list[activeIndex].price !== ''"
      >
        <div class="desc">{{ $t('carIntroduction.lowestPrice') }}</div>
        <div class="info-box flex">
          <div class="iconfont icon-riyuan"></div>
          <div class="info text-ellipsis">
            {{ list[activeIndex].price }}
          </div>
        </div>
      </div>
      <div class="desc-box">
        <div class="desc">{{ $t('carIntroduction.otherBenefits') }}</div>
        <div class="info-box flex">
          <div class="iconfont icon-fuli"></div>
          <div class="info remark text-ellipsis-4">{{ list[activeIndex].remark }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import { getCarList } from '@/api/carIntroduction'
export default {
  name: 'CarIntroduction',
  data() {
    return {
      list: [],
      swiper: null,
      activeIndex: 0
    }
  },
  watch: {
    '$i18n.locale'() {
      this.getCarList(false)
    }
  },
  mounted() {
    this.getCarList(true)
  },
  methods: {
    initSwiper() {
      const that = this
      this.swiper = new Swiper('.carInfoSwiper', {
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        loop: true, //最后一张与第一张无缝链接
        on: {
          slideChange: function() {
            const swiper = this
            that.activeIndex = swiper.realIndex
          }
        }
      })
      this.swiper.el.onmouseover = function() {
        this.swiper.autoplay.stop()
      }
      this.swiper.el.onmouseleave = function() {
        this.swiper.autoplay.start()
      }
    },
    over() {
      this.swiper.autoplay.stop()
    },
    out() {
      this.swiper.autoplay.start()
    },
    changeSwiper(type) {
      if (type === 'pre' && this.activeIndex > 0) {
        this.activeIndex--
        this.swiper.slidePrev()
      } else if (type === 'pre' && this.activeIndex === 0) {
        this.activeIndex = this.list.length - 1
        this.swiper.slidePrev()
      } else if (type === 'next' && this.activeIndex < this.list.length - 1) {
        this.activeIndex++
        this.swiper.slideNext()
      } else if (type === 'next' && this.activeIndex === this.list.length - 1) {
        this.activeIndex = 0
        this.swiper.slideNext()
      }
    },
    getCarList(flag) {
      getCarList().then(res => {
        this.list = res.data
        if (flag) {
          this.$nextTick(() => {
            this.initSwiper()
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.info-container {
  background: $bg-color;
  padding: 0 108px 92px 128px;
  height: 1080px;
  color: #ffffff;
  .left {
    width: 1140px;
    padding-top: 241px;
    position: relative;
    .swiper {
      width: 1140px;
      height: 615px;
      overflow: hidden;
      border-radius: 28px;
      .swiper-slide,
      img {
        width: 1140px;
        height: 615px;
        border-radius: 28px;
      }
    }
    .left-icon {
      left: 28px;
    }
    .right-icon,
    .left-icon {
      position: absolute;
      z-index: 9;
      top: 518px;
      right: 28px;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      background: rgba(66, 66, 69, 0.72);
      cursor: pointer;
      .icon-zuojiantou,
      .icon-youjiantou {
        font-size: 28px;
        margin-top: 2px;
      }
      .icon-zuojiantou {
        margin-left: -4px;
      }
      .icon-youjiantou {
        margin-left: 4px;
      }
    }
    .hover {
      &:hover {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0.2) 100%
          ),
          rgba(66, 66, 69, 0.72);
      }
    }
    .disabled {
      opacity: 0.36;
    }
    .custom-pagination {
      gap: 16px;
      margin-top: 84px;
      .circle {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: rgba(66, 66, 69, 0.7);
      }
      .active {
        background: rgba(245, 245, 247, 0.8);
      }
    }
  }
  .right {
    margin-left: 112px;
    padding-top: 163px;
    animation: show 2.5s forwards;
    opacity: 1;
    @keyframes show {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .name {
      font-weight: bold;
      font-size: 48px;
      line-height: 70px;
    }
    img {
      width: 432px;
      height: 220px;
      margin: 20px 0 23px;
      border-radius: 28px;
    }
    .desc-box {
      margin-bottom: 27px;
      &:last-child {
        margin-bottom: 0;
      }
      .desc {
        color: rgba(255, 255, 255, 0.56);
        font-size: 15px;
        line-height: 15px;
        font-weight: 700;
        letter-spacing: 0.151px;
        margin-left: 44px;
      }
      .info-box {
        gap: 12px;
        margin-top: 6px;
        .iconfont {
          font-size: 32px;
        }
        .info {
          color: $light-color;
          font-size: 28px;
          line-height: 32px;
          font-weight: 700;
          width: 376px;
        }
        .remark {
          line-height: normal;
        }
      }
    }
  }
}
</style>
