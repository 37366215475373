<template>
  <div>
    <car-introduction></car-introduction>
    <chartered-car id="chartered" />
  </div>
</template>

<script>
import CarIntroduction from '@/components/Moudle/CarIntroduction/CarIntroduction.vue'
import CharteredCar from '@/components/Moudle/CarIntroduction/CharteredCar.vue'

export default {
  components: { CharteredCar, CarIntroduction },
  data() {
    return {}
  },
  mounted() {
    if (this.$route.query.type == 2) {
      this.$nextTick(() => {
        let top = document.getElementById('chartered').offsetTop
        window.scrollTo({ top: top, behavior: 'smooth' })
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
