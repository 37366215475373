<template>
  <div>
    <d-dialog v-if="dialogVisible" :dialogVisible="dialogVisible" @close="close">
      <div class="content">
        <div class=" flex row-between">
          <div class="left">
            <div class="title-box flex col-center row-center">
              <div class="circle-box">
                <div class="circle"></div>
                <img src="@/assets/images/onlineMsg/process.png" />
              </div>
              <div class="text">{{ $t('common.onlineMsg.process') }}</div>
            </div>
            <div class="step flex col-center">
              <img src="@/assets/images/onlineMsg/one.png" />
              <div class="desc">
                {{ $t('common.onlineMsg.step1') }}
              </div>
            </div>
            <div class="step flex col-center">
              <img src="@/assets/images/onlineMsg/two.png" />
              <div class="desc">
                {{ $t('common.onlineMsg.step2') }}
              </div>
            </div>
            <div class="step flex col-center">
              <img src="@/assets/images/onlineMsg/three.png" />
              <div class="desc">
                {{ $t('common.onlineMsg.step3') }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title-box flex col-center row-center">
              <div class="circle-box">
                <div class="circle"></div>
                <img src="@/assets/images/onlineMsg/message.png" />
              </div>
              <div class="text">{{ $t('common.onlineMsg.message') }}</div>
            </div>
            <contact-form
              class="contact-form"
              background-color=""
              title-color="rgba(0, 0, 0, 0.85)"
              input-back-color="rgba(255, 255, 255, 0.30)"
              placeholder-color="rgba(0, 0, 0, 0.40)"
              tips-color="rgba(0, 0, 0, 0.40)"
              isCheckBack="rgba(255, 255, 255, 0.30)"
              noCheckBack="rgba(255, 255, 255, 0.30)"
              noCheckBor="rgba(0, 0, 0, 0.20)"
              isCheckLabel="rgba(0, 0, 0, 0.85)"
              isAfterCo="rgba(0, 0, 0, 0.85)"
              checkLabel="rgba(0, 0, 0, 0.85)"
              isCheckRadioColor="rgba(0, 0, 0, 0.85)"
              formLabelColor="rgba(0, 0, 0, 0.85)"
              inputColor="rgba(0, 0, 0, 0.85)"
              inputColorPla="rgba(0, 0, 0, 0.4)"
              isCheckInnerRadioColor="rgba(0, 0, 0, 0.20)"
              radioInnerColor="rgba(255, 255, 255, 0.30)"
              dateColor="rgba(0, 0, 0, 0.85)"
              radioAfter="rgba(0, 0, 0, 0.85)"
              isCheckRadioBgColor="rgba(255, 255, 255, 0.30)"
              checkWidth="22.916667vw"
              checkSpace="auto"
              ref="form"
            />
          </div>
        </div>
        <div class="btn flex row-center col-center" @click="submit">{{ $t('common.submit') }}</div>
      </div>
    </d-dialog>
    <msg-dialog ref="successDialogVisible" />
  </div>
</template>
<script>
import DDialog from '@/components/Common/Dialog/DDialog.vue'
import ContactForm from '@/components/Common/ContactForm/index.vue'
import { addDemand } from '@/api/contactUs'
import MsgDialog from '@/components/Common/Dialog/MsgDialog.vue'

export default {
  name: 'OnlineDialog',
  components: { MsgDialog, ContactForm, DDialog },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    },
    submit() {
      if (this.$refs.form.getFormData()) {
        const params = this.$refs.form.getFormData()
        let specialServices = ''
        specialServices = this.$refs.form.getFormData().specialServices
        params.specialServices = specialServices.join(',')
        addDemand(this.$refs.form.getFormData()).then(res => {
          setTimeout(() => {
            this.close()
            this.$refs.successDialogVisible.show()
          }, 1000)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  padding: 83.8px 170px 130px 120px;
  .title-box {
    margin-bottom: 70px;
    text-align: center;
    .circle-box {
      position: relative;
      .circle {
        width: 50px;
        height: 50px;
        background: linear-gradient(
          137deg,
          rgba(26, 25, 25, 0.56) 14.22%,
          rgba(187, 178, 144, 0.8) 91.43%
        );
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
      }
      img {
        width: 175px;
        height: 29px;
        top: 30px;
        left: 50px;
        position: absolute;
      }
    }

    .text {
      color: rgba(26, 25, 25, 1);
      font-weight: bold;
      font-size: 40px;
      line-height: 42px;
      letter-spacing: 0.3px;
      margin-left: -12px;
      position: relative;
    }
  }
  .left {
    .step {
      width: 440px;
      padding: 37px 36px;
      border-radius: 28px;
      border: 4px solid rgba(187, 178, 144, 0.2);
      background: rgba(255, 255, 255, 0.3);
      box-shadow: 10px 10px 10px 0 rgba(187, 178, 144, 0.25);
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        width: 32px;
        height: 46px;
        margin-right: 18px;
      }
      .desc {
        color: rgba(22, 22, 23, 1);
        font-size: 20px;
        line-height: 29px;
        font-weight: 600;
      }
    }
  }
  .btn {
    width: 121px;
    height: 44px;
    border-radius: 980px;
    background: rgba(22, 22, 23, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.3px;
    margin: 90px auto 0;
    cursor: pointer;
  }
  .right {
    .contact-form {
      margin-left: -78px;
    }
  }
}
</style>
