<template>
  <!-- 日本全境包车 -->
  <div class="chartered-car">
    <div class="box">
      <div class="title">{{ $t('navbar.charteredCar') }}</div>
      <div class="swiper carSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img :src="imgList[0]" />
          </div>
          <div class="swiper-slide">
            <img :src="imgList[1]" />
          </div>
          <div class="swiper-slide">
            <img :src="imgList[2]" />
          </div>
          <div class="swiper-slide">
            <img :src="imgList[3]" />
          </div>
        </div>
      </div>
      <div class="desc" :key="key">{{ text }}</div>
      <phone class="phone" />
    </div>
  </div>
</template>

<script>
import Phone from '@/components/Common/Phone/phone.vue'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  name: 'CharteredCar',
  components: { Phone },
  data() {
    return {
      imgList: [
        process.env.VUE_APP_FILE_URL + 'carIntroduce/engageCar/1.png',
        process.env.VUE_APP_FILE_URL + 'carIntroduce/engageCar/2.png',
        process.env.VUE_APP_FILE_URL + 'carIntroduce/engageCar/3.png',
        process.env.VUE_APP_FILE_URL + 'carIntroduce/engageCar/4.png'
      ],
      activeIndex: 0,
      text: '',
      key: 0
    }
  },
  watch: {
    '$i18n.locale'() {
      window.location.reload()
    }
  },
  created() {},
  mounted() {
    this.initSwiper()
  },
  methods: {
    initSwiper() {
      const that = this
      let stretch = 90
      let depth = 700
      console.log(window.innerWidth)
      if (window.innerWidth < 1280) {
        stretch = 90
        depth = 700
      } else if (window.innerWidth >= 1280 && window.innerWidth < 1440) {
        stretch = 90
        depth = 500
      } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
        stretch = 90
        depth = 500
      } else if (window.innerWidth === 1920) {
        stretch = 90
        depth = 700
      } else if (window.innerWidth <= 2600) {
        stretch = 90
        depth = 900
      } else {
        stretch = 90
        depth = 900
      }

      this.swiper = new Swiper('.carSwiper', {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        loop: true,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        coverflowEffect: {
          rotate: 30,
          stretch: stretch,
          depth: depth,
          modifier: 1,
          slideShadows: true
        },
        on: {
          slideChange: function() {
            const swiper = this
            that.activeIndex = swiper.realIndex
            that.key = Math.random()
            that.text = that.$i18n.t('carIntroduction.swiper' + (swiper.realIndex + 1))
          }
        }
      })
      this.swiper.el.onmouseover = function() {
        this.swiper.autoplay.stop()
      }
      this.swiper.el.onmouseleave = function() {
        this.swiper.autoplay.start()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chartered-car {
  background: $bg-color;
  padding: 131px 0 90px;
  .box {
    margin: 0 128px 0;
    background: $bg-color;
    overflow: hidden;
    .title {
      color: $light-color;
      text-align: center;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 50px;
    }

    .swiper-slide {
      width: 810px;
      height: 510px;
      img {
        width: 810px;
        height: 510px;
      }
    }

    .desc {
      color: $light-color;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.224px;
      text-align: center;
      margin-top: 29px;
      white-space: pre-line;
      animation: show 1s 500ms forwards;
      opacity: 0;
      @keyframes show {
        100% {
          opacity: 1;
        }
      }
    }
    .phone {
      margin-top: 47px;
    }
  }
}
.ja {
  .desc {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
