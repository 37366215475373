<template>
  <div>
    <div class="flex row-center">
      <div class="flex col-center row-center phone-box">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          class="phone"
        >
          <path
            d="M30.7852 23.5746C29.8996 23.4098 28.2404 24.9138 28.2404 24.9138C32.9839 27.9097 32.972 31.548 32.972 31.548C32.972 31.548 34.0509 31.0084 34.6168 30.0821C35.1825 29.1559 35.3759 28.0625 34.0009 26.148C32.6235 24.231 31.6734 23.7441 30.7852 23.5746ZM13.0982 8.11351C12.2018 8.21023 11.0547 10.1367 11.0547 10.1367C16.4678 11.6156 17.5218 15.0974 17.5218 15.0974C17.5218 15.0974 18.4396 13.9134 18.7105 12.863C18.9804 11.8137 18.8001 11.0606 16.9237 9.63066C15.0486 8.20066 13.9958 8.01445 13.0982 8.11351Z"
            fill="#F5F5F7"
          />
          <path
            d="M19.9645 2.64868C10.3391 2.64868 2.50781 10.4288 2.50781 19.992C2.50781 29.553 10.3392 37.333 19.9645 37.333C24.2854 37.333 28.2196 35.7407 31.2705 33.1458C31.5976 33.0813 32.4785 32.8258 32.2875 31.9235C32.0559 30.8158 30.3323 27.2421 27.6825 25.6713C27.6825 25.6713 26.6823 25.0792 25.8085 26.1583C25.8085 26.1583 24.443 26.6477 21.4602 23.7687C18.4773 20.8872 15.7773 18.7029 16.7537 15.7702C16.7537 15.7702 17.1905 14.2018 13.5118 12.1213C9.83426 10.0372 9.56566 11.3597 9.38426 11.9005C9.20523 12.4412 8.18828 14.4608 8.94738 16.4279C9.56566 18.0297 11.559 22.1 17.4233 27.6551C22.3446 32.3102 25.3788 34.103 27.7302 34.1531C25.4242 35.4113 22.7791 36.1345 19.9645 36.1345C11.0004 36.1345 3.70738 28.8917 3.70738 19.992C3.70738 11.09 11.0004 3.84708 19.9645 3.84708C28.9286 3.84708 36.2217 11.09 36.2217 19.992C36.2217 20.0899 36.2073 20.1854 36.2049 20.2832H37.4057C37.4081 20.1853 37.4224 20.0899 37.4224 19.992C37.4224 10.4288 29.5899 2.64868 19.9645 2.64868Z"
            fill="#F5F5F7"
          />
        </svg>
        <img class="num" src="@/assets/images/common/phone.png" />
        <div class="order flex row-center col-center" @click="order">
          {{ $t('common.order') }}
        </div>
      </div>
    </div>
    <online-dialog ref="onlineDialog" />
  </div>
</template>
<script>
import OnlineDialog from '@/components/Common/OnlineDialog/OnlineDialog.vue'

export default {
  name: 'Phone',
  components: { OnlineDialog },
  data() {
    return {}
  },
  methods: {
    order() {
      this.$refs.onlineDialog.show()
    }
  }
}
</script>
<style scoped lang="scss">
.phone-box {
  gap: 16px;
  padding: 12px 14px;
  color: #ffffff;
  height: 66px;
  box-sizing: border-box;
  //width: 349px;
  border-radius: 90px;
  background: rgba(66, 66, 69, 0.7);
  box-shadow: 0 0 1px 0 rgba(232, 232, 237, 0.11) inset;
  backdrop-filter: blur(3.5px);
  .phone {
    animation: shake 1s infinite alternate;
    width: 40px;
    height: 40px;
    svg {
      width: 40px;
      height: 40px;
    }
    @keyframes shake {
      //0% {
      //  transform: rotate(0deg);
      //}
      //
      //25% {
      //  transform: rotate(10deg);
      //}
      //
      //50% {
      //  transform: rotate(-10deg);
      //}
      //
      //75% {
      //  transform: rotate(10deg);
      //}
      //
      //100% {
      //  transform: rotate(0deg);
      //}
      0% {
        transform: rotate(0deg);
      }

      60% {
        transform: rotate(0deg);
      }

      70% {
        transform: rotate(10deg);
      }

      80% {
        transform: rotate(-10deg);
      }

      90% {
        transform: rotate(10deg);
      }

      100% {
        transform: rotate(0deg);
      }
    }
  }
  .num {
    //color: #f5f5f7;
    //font-size: 22px;
    //font-style: normal;
    //font-weight: 700;
    width: 128px;
  }
  .order {
    height: 42px;
    //padding: 6px 26px 9px;
    box-sizing: border-box;
    justify-content: center;
    //align-items: center;
    //gap: 10px;
    border-radius: 980px;
    background: $primary-color;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
  }
}
.en,
.ja {
  .order {
    padding: 0 26px;
    align-items: center;
    gap: 10px;
  }
}
.zh,
.zhf {
  .order {
    padding: 6px 26px 9px;
  }
}
</style>
